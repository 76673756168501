import {Company} from "./Company";
import {Site} from "./Site";
import {Assistant} from "./Assistant";
import {GroupContact} from "./GroupContact";
import {Civility} from "./Civility";

export enum ContactStatus {
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED',
    AWAITING_CREATION_VALIDATION = 'AWAITING_CREATION_VALIDATION',
    AWAITING_EDITION_VALIDATION = 'AWAITING_EDITION_VALIDATION',
    AWAITING_DELETION = 'AWAITING_DELETION'
}

export interface Contact {
    id: string;
    createdAt: string;
    civility: Civility;
    lastName: string;
    firstName: string;
    email: string;
    jobFunction: string;
    status: ContactStatus;
    subscribedToPublicNewsletter: boolean;
    subscribedToLaVoixDesMarques: boolean;
    subscribedToMemberNewsletter: boolean;
    extranetAccess: boolean;
    directPhoneNumber: string;
    mobilePhoneNumber: string;
    companyMainContact: boolean;
    tracked: boolean;
    role: string;
    ndaSignedAt: string;
    ndaUrl?: string;
    activatedAt: string;
    archivedAt: string;
    updatedAt: string;
    removalRequestDate: string;
    fax: string;
    twitter: string;
    linkedin: string;
    source: string;
    company?: Company;
    site?: Site;
    groupContacts: Array<GroupContact>;
    assistants?: Array<Assistant>;
    displayCivility?: string;
    displayRole?: string;
    hasLink?: boolean;
    displayStatus?: string;
    adherentCompany?: Company
    contactStatistics?: {[key: string] : {[key: string] : number}};
}

export type ReadonlyContact = Readonly<Contact>;

export interface ContactDto {
    civility: Civility;
    lastName: string;
    firstName: string;
    email: string;
    jobFunction: string;
    subscribedToPublicNewsletter: boolean;
    subscribedToLaVoixDesMarques: boolean;
    subscribedToMemberNewsletter: boolean;
    extranetAccess: boolean;
    directPhoneNumber: string;
    mobilePhoneNumber: string;
    fax: string;
    twitter: string;
    linkedin: string;
    companyId: string;
    siteId: string;
    groupIds: Array<string>;
    tracked: boolean;
}

export interface LightContactDto {
    civility: Civility;
    lastName: string;
    firstName: string;
    email?: string;
    jobFunction: string;
    phoneNumber?: string;
}

export function isActive(contact: ReadonlyContact): boolean {
    return contact?.status === ContactStatus.ACTIVE;
}

export function isAwaitingEditionValidation(contact: ReadonlyContact): boolean {
    return contact?.status === ContactStatus.AWAITING_EDITION_VALIDATION;
}

export function isAwaitingCreationValidation(contact: ReadonlyContact): boolean {
    return contact?.status === ContactStatus.AWAITING_CREATION_VALIDATION;
}

export function isArchived(contact: ReadonlyContact): boolean {
    return contact?.status === ContactStatus.ARCHIVED;
}

export function isAwaitingAnyValidation(contact: ReadonlyContact): boolean {
    return contact?.status === ContactStatus.AWAITING_CREATION_VALIDATION || contact?.status === ContactStatus.AWAITING_EDITION_VALIDATION;
}

export function isAwaitingDeletion(contact: ReadonlyContact): boolean {
    return contact?.status === ContactStatus.AWAITING_DELETION;
}
